import { ArrowClockwise } from "react-bootstrap-icons";

export const ConnectionFailed = ({ reconnect }) => {
    return (
        <div
            className={"m-auto d-flex display-4"}
            style={{ flexDirection: "column", alignItems: "center" }}
        >
            <div>Connection Failed</div>
            <button
                title="Reconnect"
                className="btn btn-lg"
                onClick={reconnect}
            >
                <ArrowClockwise
                    className={"mx-auto"}
                    style={{ fontSize: "2.2rem" }}
                />
            </button>
        </div>
    );
};

import React, { useState, useRef } from "react";
import {
    useContext
} from "react";
import { PopoverMenu } from "./PopoverMenu";
import { XLg } from "react-bootstrap-icons";

export const NotificationBox = React.memo(({ notifications, notificationMngr }) => {
    const [isVisible, setIsVisible] = useState(false);
    let btnRef = useRef(null);
    let playSound = useRef(false);
    let audioPlyr = useRef(null);
    let audio = new Audio("/mp3/notification-bell.mp3");

    let msgString = "";
    console.log("notf", notificationMngr.current.isDisplay, notificationMngr.current.permenantDisplay);
    if(notifications.length > 2){
        msgString += notifications[0].split(" ")[0];
        msgString += ", ";
        msgString += notifications[1].split(" ")[0];
        msgString += `, +${notifications.length - 2} user${(notifications.length - 2)==1 ? "": "s"} joins the conversation.`;
    }
    else if(notifications.length == 2){
        msgString += notifications[0].split(" ")[0];
        msgString += ", ";
        msgString += notifications[1].split(" ")[0];
        msgString += ` joins the conversation.`;
    }
    else if(notifications.length == 1){
        msgString += notifications[0];
        msgString += ` join the conversation.`;
    }
    
    if(isVisible){
        console.log("visible state");
        if(notificationMngr.current.notificationRingStatus){
            playSound.current = true;
        }
        setTimeout(() => {
            console.log("hiding");
            setIsVisible(false);
        }, 3000);
    }

    if(playSound.current){
        playSound.current = false;
        setTimeout(() => {
            // if(audioPlyr.current){
            //     audioPlyr.current.muted = false;
            // }
            let audioPromise = audio.play();
        }, 400);
    }

    if(notificationMngr.current.isDisplay && notificationMngr.current.permenantDisplay){
        setTimeout(() => {
            setIsVisible(true);
        }, 200);
        notificationMngr.current.isDisplay = false;
    }

    const closeClick = () => {
        setIsVisible(false);
    };

    return(
        <>
        { isVisible &&
            <div className="chat-notification-container">
                {/* <div className="chat-notification-close">x</div> */}
                <XLg className="chat-notification-close" onClick={closeClick}></XLg>
                {msgString}
                {/* <audio ref={audioPlyr} muted autoPlay={true}>
                    <source src="/mp3/notification-bell.mp3" ></source>
                    Your browser does not support the audio element.
                </audio> */}
            </div>  
        }
        </>
    );
});
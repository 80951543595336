export const MultipleChoiceResult = ({ multipleChoiceResult }) => {
    const getColor = (index) => {
        const colors = [
            "#008000",
            "#FF0000",
            "#0000FF",
            "#FFFF00",
            "#800080",
            "#ADD8E6",
            "#FFA500",
            "#006400",
            "#1A75BC"
        ];
        if (index >= colors.length) {
            return "#1A75BC";
        } else {
            return colors[index];
        }
    };
    return (
        <div className="col-sm-12 mt-5">
            <div className="row">
                <div className="col-lg-12">
                    <fieldset className="form-horizontal">
                        <div className="pr-1">
                            <div className="row Questions m-2 p-0 w-100">
                                <div className="form-group col-sm-11 col-11 m-0 p-0">
                                    <h5
                                        className="h5 font-weight-bold"
                                        name="QuestionText"
                                    >
                                        1.{" "}
                                        {multipleChoiceResult &&
                                            multipleChoiceResult?.Quizzes
                                                ?.Questions[0]?.QuestionText}
                                    </h5>
                                </div>
                                <span class="col-sm-1 col-1 float-right text-sm-center text-right p-0">
                                    <label
                                        class="badge badge-light mr-2 font-weight-bold"
                                        name="Total"
                                    >
                                        {multipleChoiceResult &&
                                            multipleChoiceResult?.Quizzes
                                                ?.pollAttended}
                                    </label>
                                </span>
                                <div
                                    id="MultipleChoice"
                                    className="col-sm-12 p-0"
                                >
                                    <div
                                        className="form-group m-0"
                                        style={{
                                            marginRight: "10px !important"
                                        }}
                                    >
                                        {multipleChoiceResult &&
                                            multipleChoiceResult?.Quizzes?.Questions[0]?.Choices?.map(
                                                (field, index) => {
                                                    return (
                                                        <div
                                                            className={`col-sm-12 AnswerText rounded-3 px-2 py-3 ${
                                                                field.IsAnswer
                                                                    ? "alert-success"
                                                                    : ""
                                                            } mt-2`}
                                                        >
                                                            <span
                                                                className="control-label inputOptiongroup text-muted h5 font-weight-bold"
                                                                name="inputOptionsR1"
                                                            >
                                                                {
                                                                    field.AnswerText
                                                                }
                                                                &nbsp;&nbsp;(
                                                                {
                                                                    field.VotedPercentage
                                                                }
                                                                %,{" "}
                                                                {
                                                                    field.VotedCount
                                                                }{" "}
                                                                Votes)
                                                            </span>
                                                            <div
                                                                className="progress mt-2 mb-1 w-100"
                                                                style={{
                                                                    height: "9px"
                                                                }}
                                                            >
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        width: field.VotedPercentage,
                                                                        backgroundColor: `${getColor(
                                                                            index
                                                                        )}`
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    );
};

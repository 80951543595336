import React from "react";
import { useContext } from "react";
import { PopoverMenu } from "./PopoverMenu";
import { ThreeDots, PinAngleFill, Trash } from "react-bootstrap-icons";
import { GeneralContext } from "../App";
export const Message = React.memo(
    ({
        message,
        date,
        left,
        isContinueMsg,
        islstMsgTimeSame,
        updateMessage
    }) => {
        const optionClick = (msg2, chatId, msgId, action) => {
            // console.log(chatId, msgId, action);
            let subaction =
                action == "delete" ? "MessageDelete" : "MessagePinChanges";

            let msg = { ...msg2 };
            msg.subAction = subaction;
            msg.action = "updatemessage";
            if (action == "delete") {
                msg.isDeleted = !msg.isDeleted;
            } else {
                msg.isPinMessage = !msg.isPinMessage;
                console.log("from event ", msg.isPinMessage);
            }
            updateMessage(msg);
        };
        return (
            <div
                className={`chat-message-${left ? "left" : "right"} ${
                    isContinueMsg ? "mt-1" : "mt-2"
                }`}
            >
                {!isContinueMsg && (
                    <div className="profile-img">
                        <ProfileImage
                            url={
                                message.profileUrl
                                    ? message.profileUrl
                                    : message.userName
                            }
                        />
                    </div>
                )}
                <MessageText
                    left={left}
                    name={!left ? "You" : message.userName}
                    text={message.text}
                    date={date}
                    isContinueMsg={isContinueMsg}
                    islstMsgTimeSame={islstMsgTimeSame}
                    optionClickAction={(selectedaction) => {
                        optionClick(
                            message,
                            message.chatId,
                            message.messageId,
                            selectedaction
                        );
                    }}
                    isPinMessage={message.isPinMessage}
                    isModerator={message.isModerator}
                />
                {/* <div>
            <span style={{marginRight: "10px"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-angle" viewBox="0 0 16 16">
                  <path d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z"></path>
            </svg></span>
            <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
            </svg></span>
            </div> */}
            </div>
        );
    }
);

const textToHtml = (str) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    // console.log(doc.body.innerHTML);
    return doc.body.innerHTML;
};

const ProfileImage = React.memo(({ url }) => {
    let nameList = url.split(" ");
    let chr = nameList[0][0];
    if (nameList[1]) {
        chr += nameList[1][0];
    }
    let tagReturn = (
        <div className="rounded-circle ml-1 mr-1 profile-text">
            {" "}
            {chr.toUpperCase()}{" "}
        </div>
    );
    if (url.startsWith("http")) {
        tagReturn = (
            <img
                src={url}
                className="rounded-circle ml-1 mr-1"
                alt="Chris Wood"
                width="35"
                height="35"
            />
        );
    } else if (url.startsWith("/")) {
        tagReturn = (
            <img
                src={document.referrer + url}
                className="rounded-circle ml-1 mr-1"
                alt="Chris Wood"
                width="35"
                height="35"
            />
        );
    }
    return tagReturn;
});

const MessageDate = React.memo(({ date }) => {
    return <span className="small text-nowrap"> {date}</span>;
});
function decodeHtmlEntities(text) {
    const element = document.createElement("div");
    if (text) {
        element.innerHTML = text;
        return element.textContent || element.innerText || "";
    }
    return text;
}
const MessageText = React.memo(
    ({
        text,
        name,
        date,
        left,
        isContinueMsg,
        islstMsgTimeSame,
        optionClickAction,
        isPinMessage,
        isModerator
    }) => {
        const { user, connectionStatus } = useContext(GeneralContext);
        return (
            <div
                className={`chat-message-text m${
                    left ? "l" : "r"
                }-2 flex-shrink-1 rounded-lg py-1 px-2 ${
                    isContinueMsg
                        ? left
                            ? " msg-cont-marg-lft"
                            : " msg-cont-marg-rght"
                        : left
                        ? " chat-message-text--left "
                        : " chat-message-text--right "
                } ${left ? " msg-bg-l" : " msg-bg-r"}`}
            >
                {!isContinueMsg && (
                    <div className="font-weight-bold mb-1">
                        {decodeHtmlEntities(name)}
                        {isModerator && (
                            <span className="badge badge-moderator">
                                Moderator
                            </span>
                        )}
                        <MessageDate date={date} />
                        {isPinMessage && (
                            <span
                                style={{
                                    marginRight: "2px",
                                    marginLeft: "3px"
                                }}
                            >
                                <PinAngleFill></PinAngleFill>
                            </span>
                        )}
                        {user.isModerator && (
                            <PopoverMenu
                                clickevent={optionClickAction}
                                isPinMsg={isPinMessage}
                                isLeft={left}
                            ></PopoverMenu>
                        )}
                    </div>
                )}
                {}
                {isContinueMsg && user.isModerator && (
                    <div style={{ float: "right" }}>
                        <PopoverMenu
                            clickevent={optionClickAction}
                            isPinMsg={isPinMessage}
                            isLeft={left}
                        ></PopoverMenu>
                    </div>
                )}
                {isContinueMsg && isPinMessage && (
                    <div
                        style={{
                            marginRight: "2px",
                            marginLeft: "3px",
                            float: "right"
                        }}
                    >
                        <PinAngleFill></PinAngleFill>
                    </div>
                )}
                {text}
                {
                    // isContinueMsg && !islstMsgTimeSame &&
                    // <div className="font-weight-bold m-0 p-0 text-right"><MessageDate date={date} /></div>
                }
            </div>
        );
    }
);

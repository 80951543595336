import { useContext, useState } from "react";

import { Loader } from ".";

import { GeneralContext } from "../App";
import { CONNECTION_STATUS } from "../utils/constants";
import { PopoverMenu } from "./PopoverMenu";
import { PopoverMenuSetting } from "./PopoverMenuSettings";

export const Header = ({notificationMngr}) => {
    const { user, connectionStatus } = useContext(GeneralContext);
    const [ notificationSoundStatus, setNotificationSoundStatus ] = useState(true);
    const [ notificationVisiblityStatus, setNotificationVisiblityStatus ] = useState(notificationMngr.current.permenantDisplay);
    
    if(notificationMngr.current.permenantDisplay && !notificationVisiblityStatus){
        setNotificationVisiblityStatus(notificationMngr.current.permenantDisplay);
    }
    else if (!notificationMngr.current.permenantDisplay && notificationVisiblityStatus)
    {
        setNotificationVisiblityStatus(notificationMngr.current.permenantDisplay);
    }

    const clickevent = (event) => {
        if(event == "notificationsound"){
            notificationMngr.current.notificationRingStatus = !notificationSoundStatus;
            setNotificationSoundStatus(!notificationSoundStatus);
        }else if(event == "notificationalert"){
            setNotificationVisiblityStatus(!notificationMngr.current.permenantDisplay);
            notificationMngr.current.permenantDisplay = !notificationMngr.current.permenantDisplay;
        }  
    };
    return (
        // <div className="py-2 px-4 border-bottom d-lg-block">
        //     <div className="d-flex align-items-center py-1">
        //         {connectionStatus === CONNECTION_STATUS.CONNECTING && (
        //             <Loader />
        //         )}
        //         {/* {connectionStatus === CONNECTION_STATUS.CONNECTED &&
        //             Object.keys(user).length !== 0 && (
        //                 <>
        //                     <div className="position-relative">
        //                         <img
        //                             src={user.profileUrl}
        //                             className="rounded-circle mr-1"
        //                             alt={user.name}
        //                             width="40"
        //                             height="40"
        //                         />
        //                     </div>
        //                     <div className="flex-grow-1 pl-3">
        //                         <strong>{user.name}</strong>
        //                         <div className="text-muted small">
        //                             {/* <em>Typing...</em> ** /}
        //                         </div>
        //                     </div>
        //                 </>
        //             )} */}
        //             {
        //                 <span style={{marginLeft: "auto"}}>
        //                 <PopoverMenuSetting clickevent={clickevent} currentNotificationStatus={notificationStatus}/>
        //                 </span>
        //             }
        //     </div>
        // </div>
        
        //As of now header is not requred so code is commented
        <div>
            { 
            <span className="chat-btn-setting">
                <PopoverMenuSetting 
                clickevent={clickevent} 
                currentNotificationSoundStatus={notificationSoundStatus} 
                currentNotificationVisiblityStatus={notificationVisiblityStatus} 
                />
            </span>
            }
        </div>
    );
};

import React, { useState, useEffect, useMemo, useRef } from "react";
import io from "socket.io-client";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { UserPolls } from "./components/UserPolls";
import {
    Container,
    Header,
    ChatBox,
    MessageList,
    InputBox,
    Loader,
    ConnectionFailed,
    NotificationBox
} from "./components";
import { CONNECTION_STATUS } from "./utils/constants";

const userList = [
    {
        id: 1,
        userId: "9ce45179-00e4-4b53-aea6-e90e655b2def",
        name: "Dev user25",
        profileUrl: "https://bootdey.com/img/Content/avatar/avatar2.png",
        isModerator: false
    },
    {
        id: 2,
        userId: "f67a63c7-a33e-4605-a3e3-26f634887d10",
        name: "rest test dev 001 01",
        profileUrl: "https://bootdey.com/img/Content/avatar/avatar2.png",
        isModerator: true
    },
    {
        id: 3,
        userId: "cc5ae7f7-92c9-4aaa-abe8-0495c888ae60",
        name: "Dev User6",
        profileUrl: "https://bootdey.com/img/Content/avatar/avatar3.png",
        isModerator: false
    },
    {
        id: 4,
        userId: "dec14ddf-79e7-478a-a6ea-4e9b3cd5e9f9",
        name: "Dev User4",
        profileUrl: "https://bootdey.com/img/Content/avatar/avatar4.png",
        isModerator: true
    },
    {
        id: 5,
        userId: "35dfad14-c69a-4026-8ff5-101f2418c958",
        name: "Dev User3",
        profileUrl: "https://bootdey.com/img/Content/avatar/avatar5.png",
        isModerator: false
    }
];
//   let uname ='';
const isDemo = window.location.href.toLowerCase().includes("isdemo=true");
const irn = Math.floor(Math.random() * 5);
// console.log("data used", (new Date).toISOString());
let socket2 = null;
if (window.ChatUser.chatEnabled && window.ChatUser.userId) {
    // console.log("set from 01");
    const encodedUserName = encodeURIComponent(window.ChatUser.name);
    socket2 = new WebSocket(
        process.env.REACT_APP_ChatUri +
            "?chatId=" +
            window.reqChatId +
            "&userId=" +
            window.ChatUser.userId +
            "&userName=" +
            encodedUserName
    );
}

export const GeneralContext = React.createContext();

function App() {
    const pollTabRef = useRef(null);
    const [user, setUser] = useState({});
    const [pollNotification, setPollNotification] = useState({});
    const [pollTabActive, setPollTabActive] = useState(false);
    const [mainDataFind, setMainData] = useState(
        window.ChatUser.userId ? true : false
    );
    const [messages, setMessages] = useState([]);
    const [pinMessage, setPinMessage] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const firstScrollData = useRef([]);
    const pagereq = useRef({ startDate: 100, limit: 50, lastKey: {} });
    const notificationMngr = useRef({
        isDisplay: false,
        notificationRingStatus: true,
        permenantDisplay: window.ChatUser.displayUserConnection,
        pandingList: [],
        timer: 0
    });
    const GoingAwayTimer = useRef(0);
    // For Below: [hasSomeoneSentNewMessage, haveISentNewMessage]
    const [hasNewMessage, setHasNewMessage] = useState([false, false]);

    const [connectionStatus, setConnectionStatus] = useState(
        CONNECTION_STATUS.CONNECTING
    );

    //This function prevent user from disconnect to the server
    //When user not send and received any message for 10 mins then aws websocket API remove it from the connected list.
    const GoingAwayTimerClear = () => {
        if (GoingAwayTimer.current != 0) {
            clearTimeout(GoingAwayTimer.current);
        }
        GoingAwayTimer.current = setTimeout(() => {
            GoingAwayTimer.current = 0;
            socket2.send(
                JSON.stringify({
                    action: "getmessages",
                    chatId: window.chatId,
                    startDate: new Date().toISOString(),
                    limit: 0
                })
            );
        }, 570000);
    };

    //when delete operation perform on any message then this function used.
    const removeMesage = (msgId) => {
        setMessages((oldMsgs) => {
            let msgs = [];
            if (oldMsgs.length > 0) {
                msgs = oldMsgs.filter(function (item) {
                    return item.messageId != msgId;
                });
            }
            return msgs;
        });
    };

    const pinMesage = (msg, msgId, isPinMsg) => {
        setMessages((oldMsg) => {
            let msgs = [...oldMsg];
            // console.log(msgs);
            if (msgs.length > 0) {
                msgs.forEach(function (item) {
                    item.isPinMessage = false;
                    if (item.messageId == msgId) {
                        item.messageId = msg.messageId;
                        item.isPinMessage = isPinMsg;
                    }
                });
                // console.log(msgs);
            }
            if (!isPinMsg) {
                setPinMessage(null);
            } else {
                setPinMessage(msg);
            }
            return msgs;
        });
    };

    useEffect(() => {
        //this demo is used for colour settings.
        //need to pass parameter in the url.
        if (isDemo) {
            //demo user
            setUser({
                id: 1,
                userId: "1",
                name: "Chat User",
                profileUrl: "",
                isDemo: true,
                isModerator: true
            });
            notificationMngr.current.permenantDisplay = true;
            //Adding demo messages
            setMessages([
                {
                    userId: "1",
                    userName: "Chat User",
                    profileUrl: "",
                    text: "Welcome to today's live stream.",
                    date: new Date().toISOString()
                },
                {
                    userId: "1",
                    userName: "Chat User",
                    profileUrl: "",
                    text: "Please introduce yourself and where you are joining us from.",
                    date: new Date().toISOString()
                },
                {
                    userId: "2",
                    userName: "John Smith",
                    profileUrl: "",
                    text: "Hi Everyone, Im joining today from Sydney, Australia",
                    date: new Date().toISOString()
                }
            ]);

            //Adding demo message listner
            setConnectionStatus(CONNECTION_STATUS.CONNECTED);
            window.setDemoMessage = () => {
                let message = {
                    action: "sendmessage",
                    userName: "webcastcloud Team",
                    profileUrl: "",
                    text: "Thank you",
                    date: new Date().getTime()
                };
                setMessages((prev) => [...prev, message]);
                setHasNewMessage([true, false]);
            };
            return;
        }

        //if not demo chat then connect to the server.
        try {
            //This need because some time requested data from parent iFrame is not received before the socket try to connect with server.
            if (!mainDataFind && window.ChatUser.userId == undefined) {
                if (window.opener == null) {
                    window.parent.postMessage(
                        JSON.stringify({ action: "getuserdata" }),
                        "*"
                    );
                    window.parent.postMessage(
                        JSON.stringify({ action: "getpollconfig" }),
                        "*"
                    );
                } else {
                    window.opener.postMessage(
                        JSON.stringify({ action: "getuserdata" }),
                        "*"
                    );
                    window.opener.postMessage(
                        JSON.stringify({ action: "getpollconfig" }),
                        "*"
                    );
                }
                setTimeout(() => {
                    setMainData(true);
                    // console.log("set from 03");
                    const encodedUserName = encodeURIComponent(
                        window.ChatUser.name
                    );
                    socket2 = new WebSocket(
                        process.env.REACT_APP_ChatUri +
                            "?chatId=" +
                            window.reqChatId +
                            "&userId=" +
                            (window.ChatUser
                                ? window.ChatUser.userId
                                : userList[irn].userId) +
                            "&userName=" +
                            (window.ChatUser.chatId
                                ? encodedUserName
                                : "Anonymous")
                    );
                    return;
                }, 1200);
            } else {
                if (window.ChatUser.userId != undefined && !mainDataFind) {
                    setMainData(true);
                }
                if (window.ChatUser.userId != undefined && socket2 == null) {
                    const encodedUserName = encodeURIComponent(
                        window.ChatUser.name
                    );
                    socket2 = new WebSocket(
                        process.env.REACT_APP_ChatUri +
                            "?chatId=" +
                            window.reqChatId +
                            "&userId=" +
                            window.ChatUser.userId +
                            "&userName=" +
                            (window.ChatUser.name
                                ? encodedUserName
                                : "Anonymous")
                    );
                    return;
                }
            }
        } catch (error) {
            console.log("error: socket init, ", error);
        }

        //Add new received message to the list
        let NewMessage = (message) => {
            setMessages((prev) => [...prev, message]);
            setHasNewMessage([true, false]);
        };

        //Add old messagess in the list.
        let OldMessages = (previousMessages) => {
            if (previousMessages.length) {
                previousMessages.forEach((msg) => {
                    if (!msg.text) {
                        msg.text = msg.MessageBody;
                        msg.profileUrl = msg.ProfileImage;
                        msg.date = msg.date;
                        msg.userName = msg.Name;
                        msg.isModerator = msg.ByModerator;
                        msg.isPinMessage = msg.IsPinnedMessage;
                        msg.chatId = msg.ChatId;
                        msg.messageId = msg.MessageId;
                    }
                });
            }
            setMessages((prev) => [...previousMessages.reverse(), ...prev]);
        };

        //Set the initial messages received.
        let InitMessages = (previousMessages, pinMessage) => {
            let msgList = previousMessages;
            if (previousMessages.length <= 20 && pinMessage != null) {
                msgList = previousMessages.filter(
                    (item) => item.messageId != pinMessage.messageId
                );
            }

            firstScrollData.current = [];
            setMessages(msgList.reverse());
            setPinMessage(pinMessage);
        };

        try {
            //this will set the event listener for chat socket.
            if (socket2 != null) {
                //This called when socket connect successfully to the server.
                socket2.onopen = function (e) {
                    console.log("socket: connected");
                    let cUser = window.ChatUser
                        ? window.ChatUser
                        : userList[irn];
                    if (isDemo) {
                        cUser.isDemo = true;
                        cUser.isModerator = true;
                    } else {
                        cUser.isDemo = false;
                    }

                    setUser(cUser);
                    notificationMngr.current.permenantDisplay =
                        window.ChatUser.displayUserConnection;

                    if (!user.userId && messages.length == 0) {
                        pagereq.current.inProgress = true;
                        pagereq.current.startDate = new Date().toISOString();
                        socket2.send(
                            JSON.stringify({
                                action: "getmessages",
                                chatId: window.chatId,
                                startDate: new Date().toISOString(),
                                limit: 80,
                                initReq: true
                            })
                        );
                    }
                };

                //Any message received from server this function is called.
                socket2.onmessage = function (evnt) {
                    // console.log(evnt.data);
                    GoingAwayTimerClear();
                    if (
                        messages.length == 0 &&
                        evnt.data.includes("Init: Record not found")
                    ) {
                        setConnectionStatus(CONNECTION_STATUS.CONNECTED);
                        return;
                    }
                    console.log("msg received");
                    let response = JSON.parse(evnt.data);
                    // console.dir(response);
                    if (Array.isArray(response)) {
                        OldMessages(response);
                        return;
                    }

                    //identifying the action.
                    if (response.action == "newmessage") {
                        NewMessage(response);
                    } else if (response.action == "getmessages") {
                        pagereq.current.inProgress = false;
                        pagereq.current.lastKey = {};
                        if (response.lastKey.chatid) {
                            pagereq.current.lastKey = {
                                chatid: { S: response.lastKey.chatid.S },
                                date: { S: response.lastKey.date.S },
                                messageid: { S: response.lastKey.messageid.S }
                            };
                        }
                        OldMessages(response.previousMessages);
                    } else if (response.action == "initmessages") {
                        pagereq.current.inProgress = false;
                        pagereq.current.lastKey = {};
                        if (response.lastKey.chatid) {
                            pagereq.current.lastKey = {
                                chatid: { S: response.lastKey.chatid.S },
                                date: { S: response.lastKey.date.S },
                                messageid: { S: response.lastKey.messageid.S }
                            };
                        }
                        setConnectionStatus(CONNECTION_STATUS.CONNECTED);
                        InitMessages(
                            response.previousMessages,
                            response.pinMessages
                        );
                    } else if (
                        response.action == "updatemessage" &&
                        response.subAction == "MessageDelete"
                    ) {
                        removeMesage(response.messageId);
                    } else if (
                        response.action == "updatemessage" &&
                        response.subAction == "MessagePinChanges"
                    ) {
                        pinMesage(
                            response,
                            response.oldMsgId,
                            response.isPinMessage
                        );
                    } else if (response.action == "sendmessageresponse") {
                        setMessages((prevMsgs) => {
                            let msgs = [...prevMsgs];
                            for (
                                let index = msgs.length - 1;
                                index > msgs.length - 5;
                                index--
                            ) {
                                const element = msgs[index];
                                if (
                                    element &&
                                    element.messageId == response.oldMsgId
                                ) {
                                    msgs[index].messageId = response.newMsgId;
                                }
                            }
                            return msgs;
                        });
                    } else if (response.action == "newuserconnected") {
                        // console.log("app", notificationMngr.current.permenantDisplay, notificationMngr.current.isDisplay);
                        notificationMngr.current.pandingList.push(
                            response.message
                        );

                        if (
                            notificationMngr.current.permenantDisplay &&
                            notificationMngr.current.timer == 0
                        ) {
                            // console.log("step1");
                            notificationMngr.current.timer = setTimeout(() => {
                                // console.log("step2");
                                notificationMngr.current.timer = 0;
                                notificationMngr.current.isDisplay = true;
                                setNotifications(
                                    notificationMngr.current.pandingList
                                );
                                notificationMngr.current.pandingList = [];
                            }, 5000);
                        }
                    }
                    // console.log(message.userName +", "+uname+ ": " + (message.userName == uname));
                };

                socket2.onclose = function (event) {
                    console.log("socket: connection closed");
                    if (event.wasClean) {
                        console.log("socket: trying to reconnect");
                        // alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                        socket2 = null;
                        setMainData(false);
                    } else {
                        // e.g. server process killed or network down
                        // event.code is usually 1006 in this case
                        console.log("socket: error from socket close");
                        console.dir(event);
                        // alert(`[close] Connection died. code=${event.code}, reason=${event.reason} `);
                        setConnectionStatus(CONNECTION_STATUS.FAILED);
                    }
                };

                socket2.onerror = function (error) {
                    console.log("socket: error from socket error listner");
                    console.dir(error);
                    setConnectionStatus(CONNECTION_STATUS.FAILED);
                    // alert(`[error] ${error.message}`);
                };
            }
        } catch (error) {
            console.log("error from set socket event listner ", error);
        }

        // socket.on("init", (initialData) => {
        //     window.chatId = "0011";
        //     console.log(initialData);

        //     setUser(initialData.user);
        //     setMessages(initialData.previousMessages);
        //     setConnectionStatus(CONNECTION_STATUS.CONNECTED);
        // });
        // window.getm=(obj)=>{
        //     socket.emit("getoldmessages", {startIndex: obj.i, limit: obj.n});
        // }
        // socket.on("oldmessages", (messages) => {
        //     console.log(messages);
        //     setMessages((prev) => [...messages.previousMessages, ...prev]);
        // });
        // socket.on("message", (message) => {
        //     if(message.userName == user.name){
        //         return;
        //     }

        //     setMessages((prev) => [...prev, message]);
        //     setHasNewMessage([true, false]);
        // });

        // socket.io.on("reconnect_attempt", (attempt) => {
        //     if (attempt >= 5) {
        //         socket.disconnect();
        //         setConnectionStatus(CONNECTION_STATUS.FAILED);
        //     }
        // });
    }, [mainDataFind]);

    const reconnect = () => {
        // socket.connect();
        if (window.ChatUser.chatEnabled && window.ChatUser.userId) {
            const encodedUserName = encodeURIComponent(window.ChatUser.name);
            socket2 = new WebSocket(
                process.env.REACT_APP_ChatUri +
                    "?chatId=" +
                    window.reqChatId +
                    "&userId=" +
                    window.ChatUser.userId +
                    "&userName=" +
                    encodedUserName
            );
        }
        setConnectionStatus(CONNECTION_STATUS.CONNECTED);
    };

    const firstScrollDataChange = () => {
        if (firstScrollData.current.length > 0) {
            setMessages(firstScrollData.current.reverse());
            firstScrollData.current = [];
        }
    };

    //When user send the data it will invoke this.
    const sendMessage = (message) => {
        if (isDemo) {
            setMessages((prev) => [...prev, message]);
            setHasNewMessage([false, true]);
            return;
        }
        // socket.emit("new-message", message);
        socket2.send(JSON.stringify(message));
        setMessages((prev) => [...prev, message]);
        setHasNewMessage([false, true]);
        GoingAwayTimerClear();
    };

    //This is for testing purpose.
    window.getMsg = () => {
        if (!pagereq.current.inProgress) {
            socket2.send(
                JSON.stringify({
                    action: "getmessages",
                    chatId: window.chatId,
                    startDate: pagereq.current.startDate,
                    limit: pagereq.current.limit,
                    lastKey: pagereq.current.lastKey
                })
            );
            pagereq.current.inProgress = true;
        }
    };

    //This is for testing purpose. it will use for show the user connects notification.
    window.setNotification = (notifcs) => {
        console.log("init");
        // console.log("moderator: ",user.isModerator);
        notificationMngr.current.isDisplay = true;
        notificationMngr.current.permenantDisplay = true;
        setNotifications(notifcs);
    };

    //It will use by scroll event listner for getting the old data.
    const getMessages = () => {
        // socket.emit("getoldmessages", pagereq);
        if (!pagereq.current.inProgress && pagereq.current.lastKey.chatid) {
            console.log("date get request send");
            socket2.send(
                JSON.stringify({
                    action: "getmessages",
                    chatId: window.chatId,
                    startDate: pagereq.current.startDate,
                    limit: pagereq.current.limit,
                    lastKey: pagereq.current.lastKey
                })
            );
            pagereq.current.inProgress = true;
        }
    };

    //This is used for updating message like pin/delete.
    const updateMessage = (updMessageObj) => {
        // console.log("update request send", updMessageObj);
        socket2.send(JSON.stringify(updMessageObj));
        if (updMessageObj.subAction == "MessageDelete") {
            let msgs = [];
            if (messages.length > 0) {
                msgs = messages.filter(function (item) {
                    return item.messageId != updMessageObj.messageId;
                });
            }
            setMessages(msgs);
        } else if (updMessageObj.subAction == "MessagePinChanges") {
            // console.log(messages);
            let msgs = [...messages];
            if (msgs.length > 0) {
                msgs.forEach((item) => {
                    // console.log(item);
                    item.isPinMessage = false;
                    if (item.messageId == updMessageObj.messageId) {
                        // console.log("changing name");
                        item.messageId = item.messageId.replace("#PinMsg", "");
                        // console.log("updobj",updMessageObj.isPinMessage);
                        item.isPinMessage = updMessageObj.isPinMessage;
                        if (updMessageObj.isPinMessage) {
                            item.messageId = "#PinMsg" + item.messageId;
                            setPinMessage(item);
                        } else {
                            setPinMessage(null);
                        }
                    }
                });
            }
            setMessages(msgs);
        }
        GoingAwayTimerClear();
    };

    const popOutChat = () => {
        // socket.emit("getoldmessages", pagereq);
        console.log("pop-out clicked.");
        if (window.opener == null) {
            window.parent.postMessage(
                JSON.stringify({ action: "pop-out-chat" }),
                "*"
            );
        } else {
            window.opener.postMessage(
                JSON.stringify({ action: "pop-out-chat" }),
                "*"
            );
        }
    };

    const generalContext = useMemo(() => {
        return { user, messages, connectionStatus, pinMessage };
    }, [user, messages, connectionStatus, pinMessage]);

    useEffect(() => {
        if (pollTabActive) {
            const interval = setTimeout(() => {
                setPollNotification({});
            }, 5000);
            return () => clearTimeout(interval);
        }
    }, [pollTabActive, pollNotification]);

    useEffect(() => {
        if (
            window.ChatUser.onlyPollsEnabled == "True" &&
            window.ChatUser.chatEnabled != "True"
        ) {
            if (pollTabRef && pollTabRef.current) {
                pollTabRef.current.click();
            }
            setPollTabActive(true);
        }
    }, [window.ChatUser]);
    return (
        <Container>
            <nav>
                <div
                    className="nav nav-tabs poll-nav-tabs border-0"
                    id="nav-tab"
                    role="tablist"
                >
                    {window.ChatUser.chatEnabled == "True" && (
                        <a
                            className={`${
                                pollTabActive ? "poll-border" : "poll-text"
                            } nav-item nav-link  poll-tab-padding border-left-0 rounded-0 py-3 border-right`}
                            id="nav-home-tab"
                            data-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                            onClick={() => setPollTabActive(false)}
                        >
                            Chat
                        </a>
                    )}

                    {window.ChatUser.onlyPollsEnabled == "True" && (
                        <>
                            <a
                                className={`nav-item nav-link poll-tab-padding py-3 border-right-0 ${
                                    pollTabActive ? "poll-text" : " poll-border"
                                }`}
                                ref={pollTabRef}
                                id="nav-profile-tab"
                                data-toggle="tab"
                                href="#nav-profile"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                                onClick={() => setPollTabActive(true)}
                            >
                                Polls
                            </a>
                            {pollNotification &&
                                pollNotification?.value?.Enabled === true && (
                                    <span className="poll-notification"></span>
                                )}
                        </>
                    )}
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                >
                    <GeneralContext.Provider value={generalContext}>
                        {!window.location.href
                            .toLowerCase()
                            .includes("displaypopout=false") && (
                            <button
                                title="Chat open in new window."
                                dataToggle="tooltip"
                                className="btn btn-custom-pop-out"
                                onClick={popOutChat}
                                style={
                                    user.isModerator
                                        ? { left: "15px" }
                                        : { right: "15px" }
                                }
                            >
                                <BoxArrowUpRight></BoxArrowUpRight>
                            </button>
                        )}
                        {user.isModerator && (
                            <Header notificationMngr={notificationMngr} />
                        )}
                        <ChatBox>
                            {connectionStatus ===
                                CONNECTION_STATUS.CONNECTING && (
                                <div
                                    className="d-flex"
                                    style={{ height: "calc(100vh - 5.7rem)" }}
                                >
                                    <Loader />
                                </div>
                            )}
                            {connectionStatus ===
                                CONNECTION_STATUS.CONNECTED && (
                                <MessageList
                                    hasNewMessage={hasNewMessage}
                                    setHasNewMessage={setHasNewMessage}
                                    getMessages={getMessages}
                                    updateMessage={updateMessage}
                                    firstScrollDataChange={
                                        firstScrollDataChange
                                    }
                                />
                            )}
                            {connectionStatus === CONNECTION_STATUS.FAILED && (
                                <div
                                    className="d-flex"
                                    style={{ height: "calc(100vh - 5.7rem)" }}
                                >
                                    <ConnectionFailed reconnect={reconnect} />
                                </div>
                            )}
                        </ChatBox>
                        <InputBox sendMessage={sendMessage} />
                        {user.isModerator && (
                            <NotificationBox
                                notifications={notifications}
                                notificationMngr={notificationMngr}
                            ></NotificationBox>
                        )}
                    </GeneralContext.Provider>
                </div>
                <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                >
                    <UserPolls setPollNotification={setPollNotification} />
                </div>
            </div>
        </Container>
    );
}

export default App;

import React from "react";
import { useLayer, Arrow } from "react-laag";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { ThreeDotsVertical, BellSlash, Bell, ChatSquare } from "react-bootstrap-icons";
export const PopoverMenuSetting = React.memo(({ clickevent, currentNotificationSoundStatus, currentNotificationVisiblityStatus }) => {
  const [isOpen, setOpen] = React.useState(false);
  
  // helper function to close the menu
  function close() {
    setOpen(false);
  }

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: "left-center", // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16 // let the arrow have some room to breath also
  });

  // Again, we're using framer-motion for the transition effect
  return (
    <>
      <button className="chat-btn-msg-option chat-btn-setting-text" {...triggerProps} style={{border: 0}} onClick={() => setOpen(!isOpen)}>
        {/* {isOpen ? "Hide" : "Show"} */}
        <ThreeDotsVertical />
      </button>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.ul {...layerProps} className="chat-ul-msg-options">
                <li className="chat-li-msg-option chat-li-pin"   onClick={() => { clickevent("notificationsound"); setOpen(!isOpen);}} >  
                    <span style={{marginRight: "5px"}}>
                        {currentNotificationSoundStatus ? <BellSlash /> : <Bell />}
                    </span>
                  {currentNotificationSoundStatus ? "Mute Notification" : "Play Notification"}
                </li>
                <li className="chat-li-msg-option chat-li-pin"   onClick={() => { clickevent("notificationalert"); setOpen(!isOpen);}} >  
                    <span style={{marginRight: "5px"}}>
                        {currentNotificationVisiblityStatus ? <ChatSquare /> : <ChatSquare />}
                    </span>
                  {currentNotificationVisiblityStatus ? "Disable Notification" : "Show Notification"}
                </li>
              {/* <Arrow {...arrowProps} /> */}
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </>
  );
});
import React, { useState, useEffect } from "react";
import "../poll.css";
import Amplify, { PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { Auth } from "aws-amplify";
import { MultipleChoiceResult } from "./MultipleChoiceResult";
export const UserPolls = ({ setPollNotification }) => {
    const [pollData, setPollData] = useState();
    const [selectedRating, setSelectedRating] = useState(0);
    const [openTextData, setOpenTextData] = useState("");
    const [multipleChoiceData, setMultipleChoiceData] = useState("");
    const [quizData, setQuizData] = useState("");
    const [postDataApiLoading, setPostDataApiLoading] = useState(false);
    const [postApiResult, setPostApiResult] = useState(false);
    const [quizCount, setQuizCount] = useState(0);
    const [validationError, setValidationError] = useState(false);
    const [surveyData, setSurveyData] = useState("");
    const [subscribeData, setSubscribeData] = useState({});
    const [pollResultData, setPollResultData] = useState();
    const [multipleChoiceResult, setMultipleChoiceResult] = useState();
    const [fileUpload, setFileUpload] = useState(null);
    const [quizCompleteData, setQuizCompleteData] = useState(null);
    const [multipleChoiceAnswerData, setMultipleChoiceAnswerData] = useState(
        []
    );
    console.log("multipleChoiceAnswerData", multipleChoiceAnswerData);
    const [multipleChoiceSubmitted, setMultipleChoiceSubmitted] =
        useState(false);
    useEffect(() => {
        if (window.ChatUser) {
            Amplify.configure({
                Auth: {
                    identityPoolId: process.env.REACT_APP_Aws_Identity_Pool_Id,
                    region: process.env.REACT_APP_Aws_Region,
                    userPoolId: process.env.REACT_APP_Aws_User_Pool_Id,
                    userPoolWebClientId:
                        process.env.REACT_APP_Aws_User_Pool_Web_Client_Id
                }
            });
            Amplify.addPluggable(
                new AWSIoTProvider({
                    aws_pubsub_region: process.env.REACT_APP_Aws_Pub_Sub_Region,
                    aws_pubsub_endpoint:
                        process.env.REACT_APP_Aws_Pub_Sub_Endpoint
                })
            );
            console.log(
                "window.ChatUser inside subscribe Data",
                window.ChatUser
            );
            const subscribe = Amplify.PubSub.subscribe(
                `wcc-${process.env.REACT_APP_Aws_Pub_Sub_Region}-livepolls-${window.ChatUser.domainId}-${window.ChatUser.chatId}`.toLowerCase()
            ).subscribe({
                next: (data) => {
                    setSubscribeData(data);
                    setPollNotification(data);
                    console.log("Message received", data);
                },
                error: (error) => console.error(error),
                close: () => console.log("Done")
            });

            return () => {
                subscribe.unsubscribe();
            };
        }
    }, [window.ChatUser]);
    useEffect((e) => {
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const handleMessage = (event) => {
        let jsonParse = JSON.parse(event.data);
        if (jsonParse.action === "connectionId") {
            const { data } = event;

            const connectionId = JSON.parse(data);
            window.connectionId = connectionId.body;
            console.log("Received message from parent:", connectionId.body);
        }
    };

    useEffect(() => {
        if (
            subscribeData &&
            subscribeData?.value?.Enabled === true &&
            window.pollConfig
        ) {
            const myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                `Bearer ${window?.pollConfig?.token}`
            );

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            fetch(
                `${window.pollConfig.livePollUrl}&sessionId=${window.connectionId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    let quizData = JSON.parse(result);
                    setQuizCompleteData(
                        quizData && quizData?.polls[0]?.pollForm?.form
                    );
                    if (
                        quizData &&
                        quizData?.polls[0]?.poll_typename === "QUIZ"
                    ) {
                        const filteredForm =
                            quizData &&
                            quizData?.polls[0]?.pollForm?.form?.filter(
                                (item) =>
                                    item.type === "radios" ||
                                    item.type === "textarea"
                            );
                        const filteredSchema = Object.fromEntries(
                            Object.entries(
                                quizData.polls[0].pollForm.schema
                            ).filter(([key]) => !key.includes("upload"))
                        );

                        const updatedData = {
                            ...quizData,
                            polls: [
                                {
                                    ...quizData.polls[0],
                                    pollForm: {
                                        ...quizData.polls[0].pollForm,
                                        form: filteredForm,
                                        schema: filteredSchema
                                    }
                                }
                            ]
                        };
                        setPollData(updatedData);
                        setQuizCount(0);
                        setMultipleChoiceAnswerData([]);
                    } else {
                        setPollData(JSON.parse(result));
                        setQuizCount(0);
                        setMultipleChoiceAnswerData([]);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    }, [subscribeData, window.pollConfig]);

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        const isChecked = e.target.checked;

        // If checkbox is checked, add the value to the array, otherwise remove it
        if (isChecked) {
            setMultipleChoiceAnswerData((prevData) => [
                ...prevData,
                selectedValue
            ]);
        } else {
            setMultipleChoiceAnswerData((prevData) =>
                prevData.filter((value) => value !== selectedValue)
            );
        }
    };

    useEffect(() => {
        if (multipleChoiceAnswerData) {
            const commaSeparatedString =
                multipleChoiceAnswerData && multipleChoiceAnswerData.join(", ");
            setMultipleChoiceData(commaSeparatedString);
        }
    }, [multipleChoiceAnswerData]);

    const handleSubmitClick = () => {
        if (
            (pollData &&
                pollData?.polls[0]?.poll_typename === "STARRATING" &&
                selectedRating === 0) ||
            (pollData &&
                pollData?.polls[0]?.poll_typename === "OPENTEXT" &&
                openTextData === "") ||
            (pollData &&
                pollData?.polls[0]?.poll_typename === "MULTIPLECHOICE" &&
                multipleChoiceData === "") ||
            (pollData &&
                pollData?.polls[0]?.poll_typename === "SURVEY" &&
                surveyData === "" &&
                selectedRating === 0) ||
            (pollData &&
                pollData?.polls[0]?.poll_typename === "QUIZ" &&
                quizData === "")
        ) {
            setValidationError(true);
        } else {
            if (
                pollData &&
                pollData.polls[0]?.totalQuestions >= quizCount + 1
            ) {
                setQuizCount((count) => count + 1);
            }
            setValidationError(false);
            setPostDataApiLoading(true);
            const formdata = new FormData();
            formdata.append(
                pollData && pollData?.polls[0]?.pollForm?.form[0]?.key,
                getPayloadData()
            );
            if (fileUpload && pollData?.polls[0]?.poll_typename === "QUIZ") {
                formdata.append("file", fileUpload);
            }
            formdata.append("user_id", window.ChatUser.userId);
            formdata.append("sessionid", `${window.connectionId}`);
            formdata.append("attempt", "1");

            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };

            fetch(
                pollData && pollData?.polls[0].poll_submit_url,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    setPostApiResult(JSON.parse(result).success);
                    setPostDataApiLoading(false);
                    if (
                        pollData?.polls[0]?.poll_typename === "MULTIPLECHOICE"
                    ) {
                        setMultipleChoiceSubmitted(true);
                    }
                    setTimeout(() => {
                        // setSubscribeData({});
                        setPostApiResult(false);
                        setMultipleChoiceSubmitted(false);
                    }, 60000);
                })
                .catch((error) => console.log("error", error));
            setQuizData("");
            setSurveyData("");
            setSelectedRating(0);
            setOpenTextData("");
            setMultipleChoiceData("");
            setFileUpload(null);
        }
    };

    const getPayloadData = () => {
        if (pollData && pollData?.polls[0].poll_typename === "STARRATING") {
            return selectedRating;
        } else if (
            pollData &&
            pollData?.polls[0].poll_typename === "OPENTEXT"
        ) {
            return openTextData;
        } else if (
            pollData &&
            pollData?.polls[0].poll_typename === "MULTIPLECHOICE"
        ) {
            return multipleChoiceData;
        } else if (pollData && pollData?.polls[0].poll_typename === "QUIZ") {
            return quizData;
        } else if (pollData && pollData?.polls[0]?.poll_typename === "SURVEY") {
            if (
                pollData &&
                pollData?.polls[0]?.pollForm.form[quizCount].type === "rating"
            ) {
                return selectedRating;
            } else {
                return surveyData;
            }
        }
    };

    useEffect(() => {
        if (pollData) {
            setPostApiResult(false);
        }
    }, [pollData]);
    console.log("pollData", pollData);
    function checkUploadById(id) {
        if (quizCompleteData) {
            for (let item of quizCompleteData) {
                if (item.key === id + "-upload") {
                    return true;
                }
            }
            return false;
        }
    }
    return (
        <div className="d-flex" style={{ height: "calc(100vh - 5.7rem)" }}>
            {window?.pollConfig?.isLive &&
                subscribeData &&
                subscribeData?.value?.Enabled === true && (
                    <>
                        {/* multiple choice */}
                        {pollData &&
                            pollData?.polls[0]?.poll_typename ===
                                "MULTIPLECHOICE" && (
                                <section className="poll-width poll-none px-4 mt-2">
                                    <div className="phone_wrapper">
                                        <main>
                                            <div className="tab_reel">
                                                <div className="tab_panel1">
                                                    <div className="card card_large">
                                                        <div className="embed-container wcc-poll-container main-poll">
                                                            {postDataApiLoading ? (
                                                                <div className="loadingblock poll-margin">
                                                                    <span>
                                                                        Please
                                                                        wait.
                                                                    </span>
                                                                    <div className="lds-dual-ring"></div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {postApiResult ? (
                                                                        <div className="poll-msg success poll-display">
                                                                            Submitted
                                                                        </div>
                                                                    ) : (
                                                                        <form enctype="multipart/form-data">
                                                                            <div>
                                                                                {validationError && (
                                                                                    <div className="poll-msg danger poll-display mb-5">
                                                                                        Please
                                                                                        choose
                                                                                        at
                                                                                        least
                                                                                        one
                                                                                        answer!
                                                                                    </div>
                                                                                )}
                                                                                <label for="multipleChoice">
                                                                                    {pollData &&
                                                                                        Object.values(
                                                                                            pollData &&
                                                                                                pollData
                                                                                                    ?.polls[0]
                                                                                                    ?.pollForm
                                                                                                    ?.schema
                                                                                        )[0]
                                                                                            .title}
                                                                                </label>

                                                                                {pollData &&
                                                                                    Object.entries(
                                                                                        pollData
                                                                                            ?.polls[0]
                                                                                            ?.pollForm
                                                                                            .form[0]
                                                                                            ?.titleMap
                                                                                    ).map(
                                                                                        (
                                                                                            field
                                                                                        ) => {
                                                                                            return (
                                                                                                <div className="poll-display form-group wcc-poll q-1">
                                                                                                    <div className="controls">
                                                                                                        <div>
                                                                                                            <div className="radio">
                                                                                                                <label>
                                                                                                                    <input
                                                                                                                        onChange={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            pollData
                                                                                                                                ?.polls[0]
                                                                                                                                ?.pollForm
                                                                                                                                ?.form[0]
                                                                                                                                ?.type ===
                                                                                                                            "checkboxes"
                                                                                                                                ? handleChange(
                                                                                                                                      e
                                                                                                                                  )
                                                                                                                                : setMultipleChoiceData(
                                                                                                                                      e
                                                                                                                                          .target
                                                                                                                                          .value
                                                                                                                                  )
                                                                                                                        }
                                                                                                                        type={
                                                                                                                            pollData
                                                                                                                                ?.polls[0]
                                                                                                                                ?.pollForm
                                                                                                                                ?.form[0]
                                                                                                                                ?.type ===
                                                                                                                            "checkboxes"
                                                                                                                                ? "checkbox"
                                                                                                                                : "radio"
                                                                                                                        }
                                                                                                                        name="multipleChoice"
                                                                                                                        value={`${field[0]}`}
                                                                                                                    />
                                                                                                                    {
                                                                                                                        field[1].split(
                                                                                                                            "<span"
                                                                                                                        )[0]
                                                                                                                    }
                                                                                                                    <span className="checkmark"></span>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn btn-steps-counter"
                                                                                >
                                                                                    1
                                                                                    /
                                                                                    1
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-steps wcc-float-right"
                                                                                    onClick={
                                                                                        handleSubmitClick
                                                                                    }
                                                                                >
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </section>
                            )}
                        {/* star rating */}
                        {pollData &&
                            pollData?.polls[0]?.poll_typename ===
                                "STARRATING" && (
                                <section className="poll-width poll-none px-4 mt-2">
                                    <div className="phone_wrapper">
                                        <main>
                                            <div className="tab_reel">
                                                <div className="tab_panel1">
                                                    <div className="card card_large">
                                                        <div className="embed-container wcc-poll-container main-poll">
                                                            {postDataApiLoading ? (
                                                                <div className="loadingblock poll-display poll-margin">
                                                                    <span>
                                                                        Please
                                                                        wait.
                                                                    </span>
                                                                    <div className="lds-dual-ring"></div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {postApiResult ? (
                                                                        <div className="poll-msg success poll-display">
                                                                            Submitted
                                                                        </div>
                                                                    ) : (
                                                                        <form enctype="multipart/form-data">
                                                                            <div>
                                                                                <div className="poll-display form-group wcc-poll wcc-rating q-1">
                                                                                    {validationError && (
                                                                                        <div className="poll-msg danger poll-display mb-5">
                                                                                            Please
                                                                                            choose
                                                                                            at
                                                                                            least
                                                                                            one
                                                                                            answer!
                                                                                        </div>
                                                                                    )}
                                                                                    <label>
                                                                                        {pollData &&
                                                                                            Object.values(
                                                                                                pollData &&
                                                                                                    pollData
                                                                                                        ?.polls[0]
                                                                                                        ?.pollForm
                                                                                                        ?.schema
                                                                                            )[0]
                                                                                                .title}
                                                                                    </label>
                                                                                    <div className="controls">
                                                                                        <div>
                                                                                            <fieldset className="rating">
                                                                                                {pollData &&
                                                                                                    Object.values(
                                                                                                        pollData &&
                                                                                                            pollData
                                                                                                                ?.polls[0]
                                                                                                                ?.pollForm
                                                                                                                ?.schema
                                                                                                    )[0]?.enum?.map(
                                                                                                        (
                                                                                                            item
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <input
                                                                                                                        type="radio"
                                                                                                                        name="starRating"
                                                                                                                        id={
                                                                                                                            item.id
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.value
                                                                                                                        }
                                                                                                                    />

                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            color: `${
                                                                                                                                parseInt(
                                                                                                                                    item.value
                                                                                                                                ) <=
                                                                                                                                parseInt(
                                                                                                                                    selectedRating
                                                                                                                                )
                                                                                                                                    ? "#FFD700"
                                                                                                                                    : ""
                                                                                                                            } `
                                                                                                                        }}
                                                                                                                        className="full"
                                                                                                                        for="starRating"
                                                                                                                        title=""
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            setSelectedRating(
                                                                                                                                item.value
                                                                                                                            )
                                                                                                                        }
                                                                                                                    ></label>
                                                                                                                </>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                            </fieldset>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn btn-steps-counter"
                                                                                >
                                                                                    1
                                                                                    /
                                                                                    1
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-steps wcc-float-right"
                                                                                    onClick={
                                                                                        handleSubmitClick
                                                                                    }
                                                                                >
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </section>
                            )}

                        {/* open text */}
                        {pollData &&
                            pollData?.polls[0]?.poll_typename ===
                                "OPENTEXT" && (
                                <section className="poll-width poll-none px-4 mt-2">
                                    <div className="phone_wrapper">
                                        <main>
                                            <div className="tab_reel">
                                                <div className="tab_panel1">
                                                    <div className="card card_large">
                                                        <div className="embed-container wcc-poll-container main-poll">
                                                            {postDataApiLoading ? (
                                                                <div className="loadingblock poll-margin">
                                                                    <span>
                                                                        Please
                                                                        wait.
                                                                    </span>
                                                                    <div className="lds-dual-ring"></div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {postApiResult ? (
                                                                        <div className="poll-msg success poll-display">
                                                                            Submitted
                                                                        </div>
                                                                    ) : (
                                                                        <form enctype="multipart/form-data">
                                                                            <div>
                                                                                <div className="poll-display form-group fileupload-1 wcc-poll q-1 q-1">
                                                                                    {validationError && (
                                                                                        <div className="poll-msg danger poll-display mb-5">
                                                                                            Please
                                                                                            choose
                                                                                            at
                                                                                            least
                                                                                            one
                                                                                            answer!
                                                                                        </div>
                                                                                    )}
                                                                                    <label for="openText">
                                                                                        {pollData &&
                                                                                            Object.values(
                                                                                                pollData &&
                                                                                                    pollData
                                                                                                        ?.polls[0]
                                                                                                        ?.pollForm
                                                                                                        ?.schema
                                                                                            )[0]
                                                                                                .title}
                                                                                    </label>
                                                                                    <div className="controls">
                                                                                        <textarea
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setOpenTextData(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                            name="openText"
                                                                                            style={{
                                                                                                height: "150px",
                                                                                                width: "100%"
                                                                                            }}
                                                                                        ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn btn-steps-counter"
                                                                                >
                                                                                    1
                                                                                    /
                                                                                    1
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-steps wcc-float-right"
                                                                                    onClick={
                                                                                        handleSubmitClick
                                                                                    }
                                                                                >
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </section>
                            )}
                        {/* survey */}
                        {pollData &&
                            pollData?.polls[0]?.poll_typename === "SURVEY" && (
                                <section className="poll-width poll-none px-4 mt-2">
                                    <div className="phone_wrapper">
                                        <main>
                                            <div className="tab_reel">
                                                <div className="tab_panel1">
                                                    <div className="card card_large">
                                                        <div className="embed-container wcc-poll-container main-poll">
                                                            {postDataApiLoading ? (
                                                                <div className="loadingblock poll-margin">
                                                                    <span>
                                                                        Please
                                                                        wait.
                                                                    </span>
                                                                    <div className="lds-dual-ring"></div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {pollData
                                                                        ?.polls[0]
                                                                        .totalQuestions <=
                                                                    quizCount ? (
                                                                        <div className="poll-msg success poll-display">
                                                                            Submitted
                                                                        </div>
                                                                    ) : (
                                                                        <form enctype="multipart/form-data">
                                                                            {validationError && (
                                                                                <div className="poll-msg danger poll-display mb-5">
                                                                                    Please
                                                                                    choose
                                                                                    at
                                                                                    least
                                                                                    one
                                                                                    answer!
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                {pollData &&
                                                                                    pollData
                                                                                        ?.polls[0]
                                                                                        ?.pollForm
                                                                                        .form[
                                                                                        quizCount
                                                                                    ]
                                                                                        .type ===
                                                                                        "radios" && (
                                                                                        <>
                                                                                            <label for="jsonform-2-elt-4980355e-cab3-4663-80e6-214601174ecf">
                                                                                                {pollData &&
                                                                                                    Object.values(
                                                                                                        pollData &&
                                                                                                            pollData
                                                                                                                ?.polls[0]
                                                                                                                ?.pollForm
                                                                                                                ?.schema
                                                                                                    )[
                                                                                                        quizCount
                                                                                                    ]
                                                                                                        .title}
                                                                                            </label>
                                                                                            {pollData &&
                                                                                                Object.entries(
                                                                                                    pollData
                                                                                                        ?.polls[0]
                                                                                                        ?.pollForm
                                                                                                        .form[
                                                                                                        quizCount
                                                                                                    ]
                                                                                                        ?.titleMap
                                                                                                ).map(
                                                                                                    (
                                                                                                        field
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <div className="poll-display form-group jsonform-error-4980355e-cab3-4663-80e6-214601174ecf wcc-poll  4980355e-cab3-4663-80e6-214601174ecf q-1">
                                                                                                                <div className="controls">
                                                                                                                    <div>
                                                                                                                        <div className="radio">
                                                                                                                            <label>
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        setSurveyData(
                                                                                                                                            e
                                                                                                                                                .target
                                                                                                                                                .value
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    type="radio"
                                                                                                                                    name="4980355e-cab3-4663-80e6-214601174ecf"
                                                                                                                                    value={`${field[0]}`}
                                                                                                                                />
                                                                                                                                {
                                                                                                                                    field[1].split(
                                                                                                                                        "<span"
                                                                                                                                    )[0]
                                                                                                                                }
                                                                                                                                <span className="checkmark"></span>
                                                                                                                            </label>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </>
                                                                                    )}

                                                                                {pollData &&
                                                                                    pollData
                                                                                        ?.polls[0]
                                                                                        ?.pollForm
                                                                                        .form[
                                                                                        quizCount
                                                                                    ]
                                                                                        .type ===
                                                                                        "textarea" && (
                                                                                        <>
                                                                                            <label for="jsonform-2-elt-4980355e-cab3-4663-80e6-214601174ecf">
                                                                                                {pollData &&
                                                                                                    Object.values(
                                                                                                        pollData &&
                                                                                                            pollData
                                                                                                                ?.polls[0]
                                                                                                                ?.pollForm
                                                                                                                ?.schema
                                                                                                    )[
                                                                                                        quizCount
                                                                                                    ]
                                                                                                        .title}
                                                                                            </label>
                                                                                            <div className="controls">
                                                                                                <textarea
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setSurveyData(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        )
                                                                                                    }
                                                                                                    name="c91ebe61-55c4-4ee3-ac3c-d22d12247e0b"
                                                                                                    style={{
                                                                                                        height: "150px",
                                                                                                        width: "100%"
                                                                                                    }}
                                                                                                ></textarea>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                {pollData &&
                                                                                    pollData
                                                                                        ?.polls[0]
                                                                                        ?.pollForm
                                                                                        .form[
                                                                                        quizCount
                                                                                    ]
                                                                                        .type ===
                                                                                        "rating" && (
                                                                                        <>
                                                                                            <div className="poll-display form-group jsonform-error-a38c0f1b-a8ed-4f03-bf09-06fee86760e3 wcc-poll wcc-rating a38c0f1b-a8ed-4f03-bf09-06fee86760e3 q-1">
                                                                                                <label for="jsonform-2-elt-4980355e-cab3-4663-80e6-214601174ecf">
                                                                                                    {pollData &&
                                                                                                        Object.values(
                                                                                                            pollData &&
                                                                                                                pollData
                                                                                                                    ?.polls[0]
                                                                                                                    ?.pollForm
                                                                                                                    ?.schema
                                                                                                        )[
                                                                                                            quizCount
                                                                                                        ]
                                                                                                            .title}
                                                                                                </label>
                                                                                                <div className="controls">
                                                                                                    <div id="jsonform-4-elt-a38c0f1b-a8ed-4f03-bf09-06fee86760e3">
                                                                                                        <fieldset className="rating">
                                                                                                            {pollData &&
                                                                                                                Object.values(
                                                                                                                    pollData &&
                                                                                                                        pollData
                                                                                                                            ?.polls[0]
                                                                                                                            ?.pollForm
                                                                                                                            ?.schema
                                                                                                                )[
                                                                                                                    quizCount
                                                                                                                ]?.enum?.map(
                                                                                                                    (
                                                                                                                        item
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <input
                                                                                                                                    type="radio"
                                                                                                                                    name="a38c0f1b-a8ed-4f03-bf09-06fee86760e3"
                                                                                                                                    id={
                                                                                                                                        item.id
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        item.value
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                <label
                                                                                                                                    style={{
                                                                                                                                        color: `${
                                                                                                                                            parseInt(
                                                                                                                                                item.value
                                                                                                                                            ) <=
                                                                                                                                            parseInt(
                                                                                                                                                selectedRating
                                                                                                                                            )
                                                                                                                                                ? "#FFD700"
                                                                                                                                                : ""
                                                                                                                                        } `
                                                                                                                                    }}
                                                                                                                                    className="full"
                                                                                                                                    for="a38c0f1b-a8ed-4f03-bf09-06fee86760e3-star5"
                                                                                                                                    title=""
                                                                                                                                    onClick={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        setSelectedRating(
                                                                                                                                            item.value
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                ></label>
                                                                                                                            </>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )}
                                                                                                        </fieldset>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                <button
                                                                                    type="button"
                                                                                    id="stepCounter"
                                                                                    className="btn btn-default btn btn-steps-counter"
                                                                                >
                                                                                    {quizCount +
                                                                                        1}{" "}
                                                                                    /{" "}
                                                                                    {
                                                                                        pollData
                                                                                            ?.polls[0]
                                                                                            .totalQuestions
                                                                                    }
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-steps wcc-float-right"
                                                                                    onClick={
                                                                                        handleSubmitClick
                                                                                    }
                                                                                >
                                                                                    {pollData
                                                                                        ?.polls[0]
                                                                                        .totalQuestions >
                                                                                    quizCount +
                                                                                        1
                                                                                        ? "Next"
                                                                                        : "Submit"}
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </section>
                            )}

                        {/* Quiz modal */}
                        {pollData &&
                            pollData?.polls[0]?.poll_typename === "QUIZ" && (
                                <section className="poll-width poll-none px-4 mt-2">
                                    <div className="phone_wrapper">
                                        <main>
                                            <div className="tab_reel">
                                                <div className="tab_panel1">
                                                    <div className="card card_large">
                                                        <div className="embed-container wcc-poll-container main-poll">
                                                            {postDataApiLoading ? (
                                                                <div className="loadingblock poll-margin">
                                                                    <span>
                                                                        Please
                                                                        wait.
                                                                    </span>
                                                                    <div className="lds-dual-ring"></div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {pollData
                                                                        ?.polls[0]
                                                                        .totalQuestions <=
                                                                    quizCount ? (
                                                                        <div className="poll-msg success poll-display">
                                                                            Submitted
                                                                        </div>
                                                                    ) : (
                                                                        <form enctype="multipart/form-data">
                                                                            {validationError && (
                                                                                <div className="poll-msg danger poll-display mb-5">
                                                                                    Please
                                                                                    choose
                                                                                    at
                                                                                    least
                                                                                    one
                                                                                    answer!
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                {pollData &&
                                                                                    pollData
                                                                                        ?.polls[0]
                                                                                        ?.pollForm
                                                                                        .form[
                                                                                        quizCount
                                                                                    ]
                                                                                        .type ===
                                                                                        "radios" && (
                                                                                        <>
                                                                                            <label for="jsonform-2-elt-4980355e-cab3-4663-80e6-214601174ecf">
                                                                                                {pollData &&
                                                                                                    Object.values(
                                                                                                        pollData &&
                                                                                                            pollData
                                                                                                                ?.polls[0]
                                                                                                                ?.pollForm
                                                                                                                ?.schema
                                                                                                    )[
                                                                                                        quizCount
                                                                                                    ]
                                                                                                        .title}
                                                                                            </label>
                                                                                            {pollData &&
                                                                                                Object.entries(
                                                                                                    pollData
                                                                                                        ?.polls[0]
                                                                                                        ?.pollForm
                                                                                                        .form[
                                                                                                        quizCount
                                                                                                    ]
                                                                                                        ?.titleMap
                                                                                                ).map(
                                                                                                    (
                                                                                                        field
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <div className="poll-display form-group jsonform-error-4980355e-cab3-4663-80e6-214601174ecf wcc-poll  4980355e-cab3-4663-80e6-214601174ecf q-1">
                                                                                                                <div className="controls">
                                                                                                                    <div>
                                                                                                                        <div className="radio">
                                                                                                                            <label>
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        setQuizData(
                                                                                                                                            e
                                                                                                                                                .target
                                                                                                                                                .value
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    type="radio"
                                                                                                                                    name="4980355e-cab3-4663-80e6-214601174ecf"
                                                                                                                                    value={`${field[0]}`}
                                                                                                                                />
                                                                                                                                {
                                                                                                                                    field[1].split(
                                                                                                                                        "<span"
                                                                                                                                    )[0]
                                                                                                                                }
                                                                                                                                <span className="checkmark"></span>
                                                                                                                            </label>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </>
                                                                                    )}

                                                                                {pollData &&
                                                                                    pollData
                                                                                        ?.polls[0]
                                                                                        ?.pollForm
                                                                                        .form[
                                                                                        quizCount
                                                                                    ]
                                                                                        .type ===
                                                                                        "textarea" && (
                                                                                        <>
                                                                                            <label for="jsonform-2-elt-4980355e-cab3-4663-80e6-214601174ecf">
                                                                                                {pollData &&
                                                                                                    Object.values(
                                                                                                        pollData &&
                                                                                                            pollData
                                                                                                                ?.polls[0]
                                                                                                                ?.pollForm
                                                                                                                ?.schema
                                                                                                    )[
                                                                                                        quizCount
                                                                                                    ]
                                                                                                        .title}
                                                                                            </label>
                                                                                            <div className="controls">
                                                                                                <textarea
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setQuizData(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        )
                                                                                                    }
                                                                                                    name="c91ebe61-55c4-4ee3-ac3c-d22d12247e0b"
                                                                                                    style={{
                                                                                                        height: "150px",
                                                                                                        width: "100%"
                                                                                                    }}
                                                                                                ></textarea>
                                                                                                {checkUploadById(
                                                                                                    pollData &&
                                                                                                        pollData
                                                                                                            ?.polls[0]
                                                                                                            ?.pollForm
                                                                                                            .form[
                                                                                                            quizCount
                                                                                                        ]
                                                                                                            ?.key
                                                                                                ) && (
                                                                                                    <div class="controls">
                                                                                                        <input
                                                                                                            class="input-file"
                                                                                                            type="file"
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                setFileUpload(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .files[0]
                                                                                                                )
                                                                                                            }
                                                                                                            accept=".jpg,.jpeg,.png,.pdf,.txt"
                                                                                                        />
                                                                                                        <span class="help-block">
                                                                                                            Please
                                                                                                            select
                                                                                                            a
                                                                                                            file
                                                                                                            less
                                                                                                            than
                                                                                                            4
                                                                                                            MB
                                                                                                        </span>
                                                                                                        <span class="help-block jsonform-errortext"></span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                <button
                                                                                    type="button"
                                                                                    id="stepCounter"
                                                                                    className="btn btn-default btn btn-steps-counter"
                                                                                >
                                                                                    {quizCount +
                                                                                        1}{" "}
                                                                                    /{" "}
                                                                                    {
                                                                                        pollData
                                                                                            ?.polls[0]
                                                                                            .totalQuestions
                                                                                    }
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-steps wcc-float-right"
                                                                                    onClick={
                                                                                        handleSubmitClick
                                                                                    }
                                                                                >
                                                                                    {pollData
                                                                                        ?.polls[0]
                                                                                        .totalQuestions >
                                                                                    quizCount +
                                                                                        1
                                                                                        ? "Next"
                                                                                        : "Submit"}
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </section>
                            )}
                    </>
                )}
            {(Object.keys(subscribeData).length === 0 ||
                subscribeData?.value?.Enabled === false ||
                (pollData && pollData.polls.length === 0)) &&
                multipleChoiceSubmitted === false && (
                    <section className="poll-width poll-none px-4 mt-2">
                        <div className="phone_wrapper">
                            <main>
                                <div className="tab_reel">
                                    <div className="tab_panel1">
                                        <div className="card card_large">
                                            <div className="embed-container wcc-poll-container main-poll">
                                                <h4>
                                                    No Polls Currently Available
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </section>
                )}
            {window?.pollConfig?.isLive &&
                subscribeData &&
                subscribeData?.value?.ResultData !== null &&
                subscribeData?.value?.Enabled === false &&
                multipleChoiceSubmitted === true &&
                pollData?.polls[0]?.poll_typename === "MULTIPLECHOICE" && (
                    <>
                        <MultipleChoiceResult
                            multipleChoiceResult={
                                subscribeData.value.ResultData
                            }
                        />
                    </>
                )}
        </div>
    );
};

import {
    useRef,
    useState,
    useEffect,
    useContext
} from "react";
import Picker from "emoji-picker-react";

import { GeneralContext } from "../App";
import { CONNECTION_STATUS } from "../utils/constants";

export const InputBox = ({ sendMessage }) => {
    
    const { user, connectionStatus } = useContext(GeneralContext);
    const [showEmojiPanel, setShowEmojiPanel] = useState(false);

    const inputRef = useRef(null);
    const emojiPanel = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOnDocument);
        // inputRef.current.focus() ;
        return () => document.removeEventListener("mousedown", handleClickOnDocument);
    }, []);

    const handleClickOnDocument = (e) => {
        if (
            !emojiPanel.current.contains(e.target) &&
            e.target.id !== "emoji-picker-button"
        ) {
            setShowEmojiPanel(false);
        }
    };

    const handleSendMessage = () => {
        setShowEmojiPanel(false);
        const message = inputRef.current.value;
        // console.log(message);
        if (message && message.trim()) {
            let isModerator = false;
            if(user.isModerator){
                isModerator = true;
            }
            if(!user.domainId){
                user.domainId = "";
            }
            sendMessage({
                action: "sendmessage",
                userName: user.name,
                domainId: user.domainId,
                userId: user.userId,
                chatId: window.chatId,
                profileUrl: user.profileUrl,
                text: message,
                date: new Date().toISOString(),
                isModerator: isModerator,
                messageId: (new Date).toISOString()
            });
            // inputRef.current.style.height = "30px";
            // inputRef.current.closest('.col-12').querySelector('#chatList').style.height = "81vh";
            inputRef.current.value = "";
        }
        setTimeout(() => {
            inputRef.current.focus(); 
        }, 200);
    };
    
    const handleHeight = () => {
        // inputRef.current.style.height = "40px";
        // inputRef.current.closest('.col-12').querySelector('#chatList').style.height = "76vh";
    }

    const onEmojiClick = (e, emojiObject) => {
        inputRef.current.value += emojiObject.emoji;
        inputRef.current.focus();
    };

    return (
        <div className="flex-grow-0 pt-2 pb-1 px-3 border-top">
            <div
                className="input-group bg-input chat-input-parent"
            >
                {/* <button
                    id={"emoji-picker-button"}
                    className={"btn mx-1 btn-lg chat-input-emoji d-none"}
                    onClick={() => setShowEmojiPanel((prev) => !prev)}
                >
                    😀
                </button> */}
                <div
                    ref={emojiPanel}
                    className={!showEmojiPanel ? "d-none" : ""}
                >
                    <Picker
                        onEmojiClick={onEmojiClick}
                        pickerStyle={{
                            position: "absolute",
                            bottom: "110%",
                            left: "0%",
                            border: "1px solid #bbb"
                        }}
                        native={true}
                    />
                </div>
                <textarea
                    id="chatinput"
                    ref={inputRef}
                    onKeyUp={(e) => e.key === "Enter" && !e.shiftKey && handleSendMessage()}
                    onKeyPress={(e) => e.key === "Enter" && e.shiftKey && handleHeight()}
                    type="text"
                    className="form-control b-0 bg-input chat-input"
                    placeholder="Type your message"
                />
                
                <button
                    className="btn mx-1 chat-input-send"
                    onClick={handleSendMessage}
                    disabled={connectionStatus !== CONNECTION_STATUS.CONNECTED}
                    
                >
                    &#10148;
                </button>
            </div>
        </div>
    );
};

export const Container = ({ children }) => {
    return (
        <main className="content">
            <div className="container-fluid p-0 px-2 ">
                <div className="card chat-card">
                    <div className="row g-0">
                        <div className="col-12 chat-box">{children}</div>
                    </div>
                </div>
            </div>
        </main>
    );
};

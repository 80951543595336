import React, { useLayoutEffect, useEffect, useContext, useRef, useState } from "react";
import { ArrowDown } from "react-bootstrap-icons";

import { Message } from "./Message";

import { GeneralContext } from "../App";
import { useOnScreen } from "../utils/hooks";
import { timeDifference } from "../utils/utility";

export const MessageList = React.memo(({ hasNewMessage, setHasNewMessage, lstUsrSet, getMessages, updateMessage, firstScrollDataChange }) => {
    const firstScroll = useRef(true);
    const { messages, user, pinMessage } = useContext(GeneralContext);
    const bottomRef = useRef(null);
    const scrollPos = useRef(-1);
    const isBottomRefVisible = useOnScreen(bottomRef);
    let lstMsgUser = "";
    let i = false;
    let lst = 0;
    const onScrollHandler = (event) => {
        scrollPos.current = event.target.scrollHeight - event.target.scrollTop;
        // console.log("scroll");
        if(firstScroll.current){
            firstScroll.current = false;
            firstScrollDataChange();
        }
        window.par = event.target;
        if(event.target.scrollTop < 200 && lst > event.target.scrollTop && i == false){
            // console.log("loading old chats");
            getMessages(event.target.scrollHeight-event.target.scrollTop);
            // console.log(i);
            i = true;
            setTimeout(() => {
                i = false;
            }, 300);
        }
        lst = event.target.scrollTop;
    }
    // let lstMsgTime = "";
    useEffect(() => {
        
        let parentnode = bottomRef?.current?.parentNode;
        parentnode.onscroll = onScrollHandler;
        // if(parentnode){
        //     parentnode.scrollTop = bottomRef?.current?.offsetTop;
        // }
        // setTimeout(() => {
        //     let parentnode = bottomRef?.current?.parentNode;
        //     if(parentnode){
        //         parentnode.scrollTop = bottomRef?.current?.offsetTop;
        //     }   
        // }, 700);
    }, []);
    useLayoutEffect(() => {
        // console.log("sc pos: "+scrollPos.current);
        if(scrollPos.current != -1){
            let parentnode = bottomRef?.current?.parentNode;
            if(parentnode){
                // console.log("setting scroll pos: "+scrollPos.current);
                parentnode.scrollTop = parentnode.scrollHeight - scrollPos.current + 35;
            }
        }
        else 
        {
            let parentnode = bottomRef?.current?.parentNode;
            if(parentnode){
                parentnode.scrollTop = bottomRef?.current?.offsetTop;
            } 
        }
    },[messages]);
    useLayoutEffect(() => {
        if (hasNewMessage[0] && isBottomRefVisible) {
            // bottomRef?.current?.scrollIntoView({
            //     behavior: "smooth"
            // });
            let parentnode = bottomRef?.current?.parentNode;
            if(parentnode){
                parentnode.scrollTop = bottomRef?.current?.offsetTop;
            }
            setHasNewMessage((prev) => [false, prev[1]]);
        } else if (hasNewMessage[1] && !isBottomRefVisible) {
            // bottomRef?.current?.scrollIntoView({
            //     behavior: "smooth"
            // });
            let parentnode = bottomRef?.current?.parentNode;
            if(parentnode){
                parentnode.scrollTop = bottomRef?.current?.offsetTop;
            }
            setHasNewMessage((prev) => [prev[0], false]);
        }
    }, [hasNewMessage, setHasNewMessage, isBottomRefVisible]);

    return (
        <>
                {
                    pinMessage != null && <div className="pl-2 pr-2 pb-1 p-sm-4 pin-message"><Message
                    key={-1}
                    left={user.userId !== pinMessage.userId}
                    message={pinMessage}
                    date={timeDifference(new Date() - new Date( pinMessage.date.endsWith('Z') ? pinMessage.date : pinMessage.date + "Z"))}
                    isContinueMsg={false}
                    updateMessage={updateMessage}
                    // islstMsgTimeSame={lstMsgTime == timeDifference(new Date() - new Date(message.time))}
                ></Message></div>
                }
            <div id="chatList"
                className="chat-messages p-2 p-sm-4 position-relative"
            >
                {pinMessage != null 
                &&
                    <span style={{visibility: "hidden"}}>
                    <Message
                    key={-1}
                    left={user.userId !== pinMessage.userId}
                    message={pinMessage}
                    date={timeDifference(new Date() - new Date( pinMessage.date.endsWith('Z') ? pinMessage.date : pinMessage.date + "Z"))}
                    isContinueMsg={false}
                    updateMessage={updateMessage}
                    // islstMsgTimeSame={lstMsgTime == timeDifference(new Date() - new Date(message.time))}
                ></Message></span>
                }
                {messages.map((message, i) => {
                    let msg = <Message
                    key={i}
                    left={user.userId !== message.userId}
                    message={message}
                    date={timeDifference(new Date() - new Date( message.date.endsWith('Z') ? message.date : message.date + "Z"))}
                    isContinueMsg={message.userId == lstMsgUser}
                    updateMessage={updateMessage}
                    // islstMsgTimeSame={lstMsgTime == timeDifference(new Date() - new Date(message.time))}
                    />
                    lstMsgUser = message.userId;
                    // lstMsgTime = timeDifference(new Date() - new Date(message.time));
                    return ( msg )
                })}
                <div ref={bottomRef}></div>
            </div>
            {hasNewMessage[0] && !isBottomRefVisible && (
                <button
                    className="btn btn-primary d-flex align-items-center position-absolute"
                    style={{
                        bottom: "3%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        boxShadow: "0 1rem 2rem rgb(0 0 0 / 20%)"
                    }}
                    onClick={() =>
                        {
                            let parentnode = bottomRef?.current?.parentNode;
                            if(parentnode){
                                parentnode.scrollTop = bottomRef?.current?.offsetTop;
                            }
                        }
                    }
                >
                    <ArrowDown />
                    <div className="ml-1">New Messages</div>
                </button>
            )}
        </>
    );
});

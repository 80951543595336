import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";

const userData = localStorage.getItem("userData");
const isInIframe = window.self !== window.top;
ReactDOM.render(
    <React.StrictMode>
        {isInIframe ? (
            <App />
        ) : (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
                <h2>Access Denied</h2>
                <p>Please log in to access the chat application.</p>
            </div>
        )}
    </React.StrictMode>,
    document.getElementById("root")
);
